var _slicedToArray = require("/home/vadim/github/arangodb/master/oskar/work/ArangoDB/js/apps/system/_admin/aardvark/APP/react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

/* jshint browser: true */

/* jshint unused: false */

/* global $, Joi, frontendConfig, arangoHelper, _, Backbone, templateEngine, window */
(function () {
  'use strict';

  window.ViewsView = Backbone.View.extend({
    el: '#content',
    readOnly: false,
    template: templateEngine.createTemplate('viewsView.ejs'),
    initialize: function initialize() {},
    refreshRate: 10000,
    sortOptions: {
      desc: false
    },
    searchString: '',
    remove: function remove() {
      this.$el.empty().off();
      /* off to unbind the events */

      this.stopListening();
      this.unbind();
      delete this.el;
      return this;
    },
    events: {
      'click #createView': 'createView',
      'click #viewsToggle': 'toggleSettingsDropdown',
      'click .tile-view': 'gotoView',
      'keyup #viewsSearchInput': 'search',
      'click #viewsSearchSubmit': 'search',
      'click #viewsSortDesc': 'sorting'
    },
    checkVisibility: function checkVisibility() {
      if ($('#viewsDropdown').is(':visible')) {
        this.dropdownVisible = true;
      } else {
        this.dropdownVisible = false;
      }

      arangoHelper.setCheckboxStatus('#viewsDropdown');
    },
    checkIfInProgress: function checkIfInProgress() {
      if (window.location.hash.search('views') > -1) {
        var self = this;

        var callback = function callback(error, lockedViews) {
          if (error) {
            console.log('Could not check locked views');
          } else {
            if (lockedViews.length > 0) {
              _.each(lockedViews, function (foundView) {
                if ($('#' + foundView.collection)) {
                  // found view html container
                  $('#' + foundView.collection + ' .collection-type-icon').removeClass('fa-clone');
                  $('#' + foundView.collection + ' .collection-type-icon').addClass('fa-spinner').addClass('fa-spin');
                } else {
                  $('#' + foundView.collection + ' .collection-type-icon').addClass('fa-clone');
                  $('#' + foundView.collection + ' .collection-type-icon').removeClass('fa-spinner').removeClass('fa-spin');
                }
              });
            } else {
              // if no view found at all, just reset all to default
              $('.tile .collection-type-icon').addClass('fa-clone').removeClass('fa-spinner').removeClass('fa-spin');
            }

            window.setTimeout(function () {
              self.checkIfInProgress();
            }, self.refreshRate);
          }
        };

        if (!frontendConfig.ldapEnabled) {
          window.arangoHelper.syncAndReturnUnfinishedAardvarkJobs('view', callback);
        }
      }
    },
    sorting: function sorting() {
      if ($('#viewsSortDesc').is(':checked')) {
        this.setSortingDesc(true);
      } else {
        this.setSortingDesc(false);
      }

      this.checkVisibility();
      this.render();
    },
    setSortingDesc: function setSortingDesc(yesno) {
      this.sortOptions.desc = yesno;
    },
    search: function search() {
      this.setSearchString(arangoHelper.escapeHtml($('#viewsSearchInput').val()));
      this.render();
    },
    toggleSettingsDropdown: function toggleSettingsDropdown() {
      var self = this; // apply sorting to checkboxes

      $('#viewsSortDesc').attr('checked', this.sortOptions.desc);
      $('#viewsToggle').toggleClass('activated');
      $('#viewsDropdown2').slideToggle(200, function () {
        self.checkVisibility();
      });
    },
    render: function render(data) {
      var self = this;

      if (data) {
        self.$el.html(self.template.render({
          views: self.applySorting(data.result),
          searchString: self.getSearchString()
        }));
      } else {
        this.getViews();
        this.$el.html(this.template.render({
          views: [],
          searchString: self.getSearchString()
        }));
      }

      if (self.dropdownVisible === true) {
        $('#viewsSortDesc').attr('checked', self.sortOptions.desc);
        $('#viewsToggle').addClass('activated');
        $('#viewsDropdown2').show();
      }

      $('#viewsSortDesc').attr('checked', self.sortOptions.desc);
      arangoHelper.setCheckboxStatus('#viewsDropdown');
      var searchInput = $('#viewsSearchInput');
      var strLength = searchInput.val().length;
      searchInput.focus();
      searchInput[0].setSelectionRange(strLength, strLength);
      arangoHelper.checkDatabasePermissions(this.setReadOnly.bind(this));
      self.events['click .graphViewer-icon-button.add'] = self.addRow.bind(self);
      self.events['click .graphViewer-icon-button.delete'] = self.removeRow.bind(self);
    },
    setReadOnly: function setReadOnly() {
      this.readOnly = true;
      $('#createView').parent().parent().addClass('disabled');
    },
    setSearchString: function setSearchString(string) {
      this.searchString = string;
    },
    getSearchString: function getSearchString() {
      return this.searchString.toLowerCase();
    },
    applySorting: function applySorting(data) {
      var self = this; // default sorting order

      data = _.sortBy(data, 'name'); // desc sorting order

      if (this.sortOptions.desc) {
        data = data.reverse();
      }

      var toReturn = [];

      if (this.getSearchString() !== '') {
        _.each(data, function (view, key) {
          if (view && view.name) {
            if (view.name.toLowerCase().indexOf(self.getSearchString()) !== -1) {
              toReturn.push(view);
            }
          }
        });
      } else {
        return data;
      }

      return toReturn;
    },
    gotoView: function gotoView(e) {
      var name = $(e.currentTarget).attr('id');

      if (name) {
        var url = 'view/' + encodeURIComponent(name);
        window.App.navigate(url, {
          trigger: true
        });
      }
    },
    getViews: function getViews() {
      var self = this;
      this.collection.fetch({
        success: function success(data) {
          var res = {
            result: []
          };
          self.collection.each(function (view) {
            res.result.push(view.toJSON());
          });
          self.render(res);
          self.checkIfInProgress();
        },
        error: function error(_error) {
          console.log(_error);
        }
      });
    },
    createView: function createView(e) {
      if (!this.readOnly) {
        e.preventDefault();
        this.createViewModal();
      }
    },
    createViewModal: function createViewModal() {
      var buttons = [];
      var tableContent = [];
      var advanced = [];
      var primarySortTableContent = [];
      var storedValuesTableContent = [];
      var advancedTableContent = [];
      tableContent.push(window.modalView.createTextEntry('newName', 'Name', '', false, 'Name', true, [{
        rule: Joi.string().regex(/^[a-zA-Z0-9\-_]*$/),
        msg: 'Only symbols, "_" and "-" are allowed.'
      }, {
        rule: Joi.string().required(),
        msg: 'No view name given.'
      }]));
      tableContent.push(window.modalView.createReadOnlyEntry(undefined, 'Type', 'arangosearch', undefined, undefined, false, undefined));
      tableContent.push(window.modalView.createSelectEntry('newPrimarySortCompression', 'Primary Sort Compression', 'lz4', false, [window.modalView.createOptionEntry('LZ4', 'lz4'), window.modalView.createOptionEntry('None', 'none')], 'width: unset;'));
      primarySortTableContent.push(window.modalView.createTableEntry('newPrimarySort', {
        noLabel: true // Dummy object. Entry will only be created if this is set as a String.

      }, ['Field', 'Direction'], [[window.modalView.createTextEntry(undefined, undefined, '', false, undefined, true, [{
        rule: Joi.string().required(),
        msg: 'No field name given.'
      }]), window.modalView.createSelectEntry(_.uniqueId('direction-'), undefined, 'asc', false, [window.modalView.createOptionEntry('ASC', 'asc'), window.modalView.createOptionEntry('DESC', 'desc')])]], undefined, 'padding-left: 0;'));
      storedValuesTableContent.push(window.modalView.createTableEntry('newStoredValues', {
        noLabel: true // Dummy object. Entry will only be created if this is set as a String.

      }, ['Fields', 'Compression'], [[window.modalView.createSelect2Entry(_.uniqueId('field-'), null, null, null, null, true, false, false, null), window.modalView.createSelectEntry(_.uniqueId('compression-'), undefined, 'lz4', false, [window.modalView.createOptionEntry('LZ4', 'lz4'), window.modalView.createOptionEntry('None', 'none')])]], undefined, 'margin-top: 10px; padding-left: 0;'));
      advancedTableContent.push(window.modalView.createTextEntry('newWriteBufferIdle', 'Write Buffer Idle', 64, false, undefined, false, [{
        rule: Joi.number().integer().min(0),
        msg: 'Only non-negative integers allowed.'
      }]));
      advancedTableContent.push(window.modalView.createTextEntry('newWriteBufferActive', 'Write Buffer Active', '0', false, undefined, false, [{
        rule: Joi.number().integer().min(0),
        msg: 'Only non-negative integers allowed.'
      }]));
      advancedTableContent.push(window.modalView.createTextEntry('newWriteBufferSizeMax', 'Write Buffer Size Max', 33554432, false, undefined, false, [{
        rule: Joi.number().integer().min(0),
        msg: 'Only non-negative integers allowed.'
      }]));
      advanced.push({
        header: 'Primary Sort',
        content: primarySortTableContent
      });
      advanced.push({
        header: 'Stored Values',
        content: storedValuesTableContent
      });
      advanced.push({
        header: 'Advanced',
        content: advancedTableContent
      });
      buttons.push(window.modalView.createSuccessButton('Create', this.submitCreateView.bind(this)));
      window.modalView.show('modalTable.ejs', 'Create New View', buttons, tableContent, advanced, undefined, this.events); // select2 workaround

      this.select2FixSearchBar();
    },
    select2FixSearchBar: function select2FixSearchBar() {
      $('.select2-search-field input').on('focusout', function (e) {
        if ($('.select2-drop').is(':visible')) {
          if (!$('#select2-search-field input').is(':focus')) {
            window.setTimeout(function () {
              $(e.currentTarget).parent().parent().parent().select2('close');
            }, 200);
          }
        }
      });
    },
    addRow: function addRow(e) {
      e.stopPropagation();
      var row = $(e.currentTarget).closest('table').find('tbody').children().last();
      var foundSelect2 = false;

      if (row.find('.select2-container').length > 0) {
        foundSelect2 = true;
      }

      var newRow;

      if (!foundSelect2) {
        newRow = row.clone(true);
      } else {
        newRow = row.clone(false);
      }

      var idParts = newRow.attr('id').split('-');
      idParts[idParts.length - 1] = parseInt(idParts[idParts.length - 1]) + 1;
      var newId = idParts.join('-');
      newRow.attr('id', newId);
      var select2id = '';

      if (foundSelect2) {
        select2id = newId + '-select2';
        var firstCell = newRow.find('td:first-child');
        firstCell.html('<input type="hidden" id="' + select2id + '"/>');
      } else {
        var inputs = newRow.find('input,textarea');
        inputs.val('');
        inputs.attr('id', function (idx, id) {
          return id ? "".concat(id.split('-')[0], "-").concat(_.uniqueId()) : id;
        });
      }

      if (!newRow.find('button.delete').length) {
        var lastCell = newRow.children().last().find('span');
        $("\n            <button style=\"margin-left: 5px;\"\n                    class=\"graphViewer-icon-button gv_internal_remove_line gv-icon-small delete addDelete\">\n            </button>\n        ").appendTo(lastCell);
      }

      newRow.insertAfter(row);

      if (foundSelect2) {
        // unfortunately we need to trigger select2 creation after input gets rendered
        window.modalView.handleSelect2Row({
          id: select2id,
          width: "resolve"
        });
        this.select2FixSearchBar();
      }
    },
    removeRow: function removeRow(e) {
      e.stopPropagation();
      $(e.currentTarget).closest('tr').remove();
    },
    submitCreateView: function submitCreateView() {
      var self = this;
      var name = $('#newName').val();
      var primarySortCompression = $('#newPrimarySortCompression').val();
      var writebufferIdle = parseInt($('#newWriteBufferIdle').val());
      var writebufferActive = parseInt($('#newWriteBufferActive').val());
      var writebufferSizeMax = parseInt($('#newWriteBufferSizeMax').val());
      var primarySort = [];
      $('#newPrimarySort tbody').children().each(function (idx, tr) {
        var inputs = $(tr).find('input,select');

        var _inputs$map = inputs.map(function (idx, input) {
          return input.value;
        }),
            _inputs$map2 = _slicedToArray(_inputs$map, 2),
            field = _inputs$map2[0],
            direction = _inputs$map2[1];

        if (field) {
          primarySort.push({
            field: field,
            direction: direction || 'asc'
          });
        }
      }); // Section: "Stored Values"

      var storedValues = [];
      $('#newStoredValues tbody').children().each(function (idx, tr) {
        var s2field = $(tr).find('.select2-container');
        var compression = $(tr).find('select').val();

        if (s2field) {
          var s2dataObj = $(s2field).select2('data');
          var fieldsArr = [];

          if (s2dataObj.length > 0) {
            _.each(s2dataObj, function (value, key) {
              fieldsArr.push(value.text);
            });
          }

          storedValues.push({
            fields: fieldsArr,
            compression: compression || 'lz4'
          });
        }
      });
      var options = JSON.stringify({
        name: name,
        type: 'arangosearch',
        primarySortCompression: primarySortCompression,
        writebufferIdle: writebufferIdle,
        writebufferActive: writebufferActive,
        writebufferSizeMax: writebufferSizeMax,
        primarySort: primarySort,
        storedValues: storedValues
      });
      $.ajax({
        type: 'POST',
        cache: false,
        url: arangoHelper.databaseUrl('/_api/view'),
        contentType: 'application/json',
        processData: false,
        data: options,
        success: function success(data) {
          window.modalView.hide();
          arangoHelper.arangoNotification('View', 'Creation in progress. This may take a while.');
          self.getViews();
        },
        error: function error(_error2) {
          if (_error2.responseJSON && _error2.responseJSON.errorMessage) {
            arangoHelper.arangoError('Views', _error2.responseJSON.errorMessage);
          }
        }
      });
    }
  });
})();